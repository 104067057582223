import { useCallback } from 'react';
import { Button } from 'antd';
import { useCrudData } from './crudContext';
import { useTableData } from './tableContext';
import { applyPromiseToasterEffects, secureApi } from '../../../hooks/api';
import { useFeedback } from '../../../hooks/useFeedback';
export function BulkDelete() {
  const {
    selection: { selectedRowKeys },
  } = useTableData();
  const { modal } = useFeedback();
  const { refresh, apiUrl } = useCrudData();
  const onConfirm = useCallback(async () => {
    await applyPromiseToasterEffects(
      secureApi(`${apiUrl}/${selectedRowKeys.join(',')}`, {
        method: 'DELETE',
      }),
      {
        errorMessage: 'Error while deleting the selected records',
        successMessage: 'Records deleted successfully',
      }
    );
    refresh();
  }, [selectedRowKeys]);
  return (
    <Button
      className="bulk-delete-btn red-btn-text"
      onClick={() => {
        modal.confirm({
          title: 'Are you sure to delete?',
          content: 'These records will be deleted permanently from the system',
          onOk: onConfirm,
          okType: 'danger',
        });
      }}
    >
      Delete Selected
    </Button>
  );
}

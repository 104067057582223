import { jsUcfirst, camelize } from '@arivaa-react/helpers/common';

let obj = {};
export default obj;
function requireAll(r) {

  r.keys().map((r) => {
    if (r.endsWith('test.js')) {
      return;
    }
    if (
      r !== './index.js' &&
      r.endsWith('/index.js') &&
      r.split('/').length === 3
    ) {
      const name = r.replace('.js', '');
      const file = require(`${name}`).default;
      if (file) {
        const fileName = name.replace('./', '').replace('/index', '');
        obj[fileName] = file;
        if (file.form) {
          const formName = (jsUcfirst(camelize(fileName)) + 'Form').replace(
            /-/g,
            ''
          );
          file.formId = formName;
        }
      }
    }
    return r;
  });
}
requireAll(require.context('./', true, /\.js$/));

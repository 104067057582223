import { useCallback } from 'react';
import { Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { secureApi } from '../../../../hooks/api';
import { useCrudData } from '../crudContext';

function downloadCsvFromJson(
  jsonData,
  fileName = 'file.csv',
  title,
  showHeader
) {
  const arrData = typeof jsonData != 'object' ? JSON.parse(jsonData) : jsonData;
  let CSV = '';
  //Set Report title in first row or line
  if (title) {
    CSV += title + '\r\n\n';
  }
  //This condition will generate the Label/Header
  if (showHeader) {
    let row = '';
    //This loop will extract the label from 1st index of on array
    for (let index in arrData[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    CSV += row + '\r\n';
  }
  //1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = '';
    //2nd loop will extract each column and convert it in string comma-seprated
    for (let index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }
    row.slice(0, row.length - 1);
    //add a line break after each row
    CSV += row + '\r\n';
  }
  //Initialize file format you want csv or xls
  const uri = 'data:text/csv;charset=utf-8,' + CSV;
  // now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension
  //this trick will generate a temp <a /> tag
  const link = document.createElement('a');
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.style = 'visibility:hidden';
  link.download = fileName;
  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function SampleCsvExport() {
  const { apiUrl } = useCrudData();
  const handleClick = useCallback(async () => {
    const res = await secureApi(`${apiUrl}/schema/create`);
    downloadCsvFromJson(res, 'sample.csv', null, false);
  }, []);

  return (
    <Space onClick={handleClick}>
      <DownloadOutlined /> Download sample CSV
    </Space>
  );
}

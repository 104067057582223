import { useCallback } from 'react';
import { FloatButton, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { secureApi } from '../../../hooks/api';
import { useCrudData } from './crudContext';
import { useTableData } from './tableContext';
import { JsonToCsvConvertor } from '@arivaa-react/helpers/common';

/**
 * @description Csv Export
 * @type Component
 * @author
 */

export function CsvExport({ fileName, columns }) {
  const { visibleColumns, selection } = useTableData();
  const { apiUrl, query } = useCrudData();
  columns = columns || visibleColumns;
  fileName = fileName || 'Worksheet';

  /**
   * Get Initial Data
   * @returns {[Array]}
   */
  const getData = useCallback(async () => {
    const { page, size, ...params } = query;
    return await secureApi(apiUrl, {
      params: {
        ...params,
        all: true,
      },
    });
  }, []);

  /**
   * downloadSheet
   */
  const downloadSheet = useCallback(async () => {
    JsonToCsvConvertor(
      selection.selectedRows.length > 0
        ? selection.selectedRows
        : await getData(),
      fileName,
      false,
      true,
      columns.map((item) => item.dataIndex)
    );
  }, [columns, fileName, selection]);

  return (
    <Tooltip title={`Export table`}>
      <FloatButton onClick={downloadSheet} icon={<DownloadOutlined />} />
    </Tooltip>
  );
}

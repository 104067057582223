import { Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useCrudData } from '../crudContext';
import { applyPromiseToasterEffects, secureApi } from '../../../../hooks/api';
import { useCrudRecord } from '../recordContext';
import { translate } from '../../../../localization';
import { useFeedback } from '../../../../hooks/useFeedback';
export function DeleteRecord() {
  const { refresh, apiUrl } = useCrudData();
  const { modal } = useFeedback();
  const { key } = useCrudRecord();
  const onOk = async () => {
    await applyPromiseToasterEffects(
      secureApi(`${apiUrl}/${key}`, {
        method: 'DELETE',
      }),
      {
        successMessage: translate('crud.delete.success'),
        throwError: true,
      }
    );
    refresh();
  };
  return (
    <Tooltip key="delete" placement="top" title={translate('crud.delete')}>
      <Button
        className={`delete`}
        onClick={() => {
          modal.confirm({
            title: translate('crud.delete.title'),
            content: translate('crud.delete.content'),
            okText: translate('crud.delete.ok'),
            okType: 'danger',
            cancelText: translate('crud.delete.cancel'),
            onOk,
          });
        }}
      >
        <DeleteOutlined />
      </Button>
    </Tooltip>
  );
}

import { useCallback, useState } from 'react';
import { parseJSON } from '@arivaa-react/helpers/common';
const COLUMNS_LOCAL_STORAGE_PREFIX = 'crud-table-columns-info-';

export function useToggleColumns(
  entity,
  persistToggleColumnState = false
) {
  const storage = persistToggleColumnState
    ? window.localStorage
    : window.sessionStorage;
  const storageKey = COLUMNS_LOCAL_STORAGE_PREFIX + entity;
  const [activeColumns, setActiveColumns] = useState(
    parseJSON(storage?.getItem(storageKey)) || {}
  );
  const isColumnSelected = useCallback(
    (ele) => activeColumns[ele.title] != false,
    [activeColumns]
  );
  const onToggleColumn = useCallback(
    (activeColumns) => {
      storage.setItem(storageKey, JSON.stringify(activeColumns));
      setActiveColumns(activeColumns);
    },
    [activeColumns]
  );

  return { isColumnSelected, onToggleColumn };
}

import { ModalTrigger } from '@arivaa-react/components/modal';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useCrudData } from '../crudContext';
import { useCrudRecord } from '../recordContext';
import { translate } from '../../../../localization';

export function EditRecord({ modalProps }) {
  const { FormComponent } = useCrudData();
  const data = useCrudRecord();
  return FormComponent ? (
    <ModalTrigger
      modalProps={{
        title: 'Edit',
        footer: null,
        className: `edit-record-modal ${modalProps?.className || ''}`,
        ...modalProps,
      }}
      content={<FormComponent data={data} />}
    >
      <Tooltip placement="top" title={translate('crud.edit')}>
        <Button className="edit">
          <EditOutlined />
        </Button>
      </Tooltip>
    </ModalTrigger>
  ) : null;
}

import { createContext, useContext } from 'react';

export const defaultTableContext = {
  columns: [],
  visibleColumns : [],
  isColumnSelected : () => {},
  onToggleColumn: () => {},
  selection : {
    selectedRowKeys: [],
    selectedRows: [],
  }
};

const TableContext = createContext(defaultTableContext);

export const TableProvider = TableContext.Provider;
export const useTableData = () => useContext(TableContext);

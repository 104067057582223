import { useCallback, useRef, useState } from 'react';

export const useOtpInput = (initialValue, onChange = () => {}, length = 4) => {
  const [value, setValue] = useState(
    initialValue ? initialValue.split('') : Array(length)
  );
  const refArr = Array(length)
    .fill(1)
    .map(() => useRef(null));
  const onChangeHandler = useCallback(
    (index, val) => {
      setValue((prev) => {
        const newArr = [...prev];
        newArr[index] = val;
        if (!newArr.find((e) => e === undefined)) {
          onChange instanceof Function && onChange(newArr.join(''));
        }
        return newArr;
      });
      if (isNaN(val)) {
        val = undefined;
      } else {
        refArr[index + 1]?.current?.focus();
      }
    },
    [refArr, onChange, value]
  );

  return {
    onChangeHandler,
    value,
    refArr,
  };
};

import { createContext, useContext } from 'react';
const getPageSize = () => {
  const { innerHeight: height = 0 } = window;
  return 5 * Math.ceil(height / 500) || 10;
};

export const defaultQuery = {
  search: [],
  sort: ['createdAt'],
  sortType: ['desc'],
  page: 1,
  size: getPageSize(),
};
export const defaultCrudContext = {
  apiUrl: undefined,
  query: defaultQuery,
  data: {
    data: [],
    total: 0,
  },
  loading: false,
  updateQuery: (_ = {}) => {},
  refresh: () => {},
  FormComponent: () => null,
};

const CrudContext = createContext(defaultCrudContext);

export const CrudProvider = CrudContext.Provider;
export const useCrudData = () => useContext(CrudContext);

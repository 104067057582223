import { MailOutlined } from '@ant-design/icons';
import Link from '@arivaa-react/components/link';
import OtpForm from './otp-form';
import { isDemoEnabled } from '../../../../hooks/useAppConfig';

/**
 * @description Email Login Form
 * @type Component
 * @author Inderdeep
 */

export const LoginForm = () => {
  const elements = [
    {
      type: 'email',
      name: 'email',
      required: true,
      inputProps: {
        placeHolder: 'Enter Email',
      },
      options: {
        initialValue: isDemoEnabled ? 'admin@admin.com' : undefined,
      },
    },
  ];
  return <OtpForm type={'email'} elements={elements} />;
};

export const ButtonLink = () => (
  <Link routeKey="login" routeParams={{ type: 'email-otp' }}>
    <div className="auth-option">
      <span className="icon">
        <MailOutlined />
      </span>
      <p className="text">Email OTP</p>
    </div>
  </Link>
);
